html {
	font-size: 80%;
}

html .invalid-feedback {
	font-size: 100%;
}

.manual-menu {

	.dropdown-item.active,
	.dropdown-item:active {
		background-color: #3d5268;
		color: #333;
	}
}


@media (min-width: 1400px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1140px
	}

}

// simulation table
table.simulation-table {
	font-weight: 400;
	line-height: 1.5;
	color: #495057;

	line-height: 1.5;

	td.main {
		vertical-align: top;
	}

	font-size: 12px;
	padding: 5px;
	margin: 5px auto;

	div.image-area {
		// padding: 50px 0;
		border: 3px solid #bbb;
		min-width: 400px;
		height: 690px;
		text-align: center;
		display: table-cell;
		vertical-align: middle;
	}

	.jobno,
	.concept-title {
		font-size: 14px;
	}

	.jobno {
		text-align: right;
	}

	div.explain {
		margin-top: 5px;
		font-size: 13px;
		text-align: center;
	}

	div.title {
		font-size: 14px;
		width: 200px;
		margin: 5px;
		padding: 5px 8px;
		display: inline-block;
		color: white;
		background-color: #333;
	}

	hr.dashed {
		border-top: 3px dashed #bbb;
		border-bottom: 0px;
		margin-top: 5px;
		margin-bottom: 10px;
	}

	img {
		max-height: 690px;
		max-width: 400px;
	}
}

table.result-table.norm {
	tr td:not(:first-child) {
		width: 75px;
	}

	tr td:first-child {
		width: 100px;
	}
}

table.correct-table {
	font-size: 12px;
	border-collapse: collapse;

	th,
	td {
		border: 1px solid #bbb;
		padding: 2px;
		text-align: center;
	}

	th {
		background-color: #ddd;
	}

	td {
		background-color: white;
	}
}

table.result-table {
	font-size: 12px;
	border-collapse: collapse;

	td.label-area,
	td.goal,
	td.total {
		background-color: #ddd;
	}

	th,
	td {
		border: 1px solid #bbb;
		padding: 2px;
	}

	tr:first-child th:first-child {
		border: 0px;
		background-color: white;
	}

	th {
		background-color: #ddd;
	}

	tr td:not(:first-child) {
		width: 100px;
	}

	tr td:first-child {
		width: 155px;
	}

	th,
	td {
		text-align: center;
	}

	th.subtitle {
		text-align: left;
	}

	td.minus {
		color: red;
	}
	.originality,.strategy__originality{
		background-color: #c00000 !important;
		color: white;
	}
	.action-standard {
		background-color: #c00000 !important;
		color: white;
	}

	tr.action-standard {
		td {
			background-color: #c00000;
			color: white;
		}

		td.none-value {
			border: 0px;
			background-color: white;
		}
	}

	td.trial {
		background-color: #FBC2A4 !important;
	}

	td.repeat {
		background-color: #FEE6AA !important;
	}

	td.localOther {
		background-color: #CDF1BA !important;
	}

	td.inbound {
		background-color: #C1D4F4 !important;
	}

	tr td.reason-area {
		width: 500px;
	}

	.form-control {
		height: auto !important;
		padding: 1px;
	}

	input.form-control {
		font-size: 12px;
	}

	textarea.form-control {
		font-size: 11px;
		resize: none;
		overflow: hidden;
	}

	input.form-control {
		text-align: center;
	}

	textarea.form-control {
		letter-spacing: -0.1px;
	}
}

table.wrap-table {
	width: 100%;

	table.result-table {
		width: 100%;
	}

	td.table-area {
		table {
			margin: 13px 0;
		}
	}
}

table.sub-table td.title-area {
	padding: 0px 7px 0px 4px;
	text-align: center;
}

table.simulation {
	margin-top: 3px;
}

span.small-font {
	font-size: 9px;
}


// simulation container
div.simulation-container {
	font-weight: 400;
	color: #495057;
	line-height: 1.5;
	font-size: 12px;
	padding: 5px 5px 0px 5px;
    margin: 5px auto;

	display: flex;
	flex-direction: column;
	width: 100%;

	.row {
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 1px;
	  }

	  .column {
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: 1px;
	  }
	  

	  .item {
		display: flex;
		align-items: center;
		justify-content: center;

		.content-left {
			text-align: left;
			width:100%;
		}
		.content-right {
			text-align: right;
			width:100%;
		}
	
		&.concept-title {
		  flex: 2;
		  text-align: left;
		}
	
		&.jobno {
		  flex: 1;
		  text-align: right;
		}
	
		&.main {
		  flex: 1;
		  max-width: 400px;

		  .image-area{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			border: 3px solid #bbb;

			img {
				object-fit: contain; /* 画像のアスペクト比を保つ */
			}
		  }
		}
	
		&.title-area {
			flex: 1;
			max-width:30px;

			background-color: #404040;
			color:white;
			margin-right: 5px;
			margin-left: 5px;
			display: flex;

			.rotate-label {
				rotate: -90deg;
				display: flex;
				justify-content: center;
				align-items: center;
				white-space: nowrap; /* テキストが折り返されないようにする */
				word-spacing: 10px; 	
			}

		}
	
		&.graph-area {
		  flex: 1;
		  text-align: left;
		}
	
		&.separator {
		  flex: 1;
		  padding-left: 5px;
		  padding-right: 5px;
		}
	  }
	
	.jobno,
	.concept-title {
		font-size: 14px;
	}

	.jobno {
		text-align: right;
	}

	div.explain {
		margin-top: 5px;
		font-size: 13px;
		text-align: center;
	}

	div.title {
		font-size: 14px;
		width: 200px;
		margin: 5px;
		padding: 5px 8px;
		display: inline-block;
		color: white;
		background-color: #333;
	}

	hr.dashed {
		border-top: 3px dashed #bbb;
		border-bottom: 0px;
		margin-top: 5px;
		margin-bottom: 10px;
		width: 100%;
	}

	img {
		max-height: 690px;
		max-width: 100%;
	}
}